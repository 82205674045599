<template>
  <b-row class="filters-container">
    <b-col cols="4">
      <label for="healthPlan">Convênio</label>
      <HealthPlanFilter :selectHealthPlan="handleSelectedHealthPlans" />
    </b-col>

    <b-col cols="3">
      <label for="batch_number">Nº do Lote</label>
      <b-form-input
        id="batch_number"
        placeholder="Pesquisar"
        v-model="filters.batchNumber"
        debounce="500"
      />
    </b-col>

    <b-col cols="2">
      <label for="invoice_number">Nº da Fatura</label>
      <b-form-input
        id="invoice_number"
        debounce="500"
        placeholder="Pesquisar"
        v-model="filters.invoiceNumber"
      />
    </b-col>

    <b-col cols="3">
      <div class="label-container">
        <label for="periods">Período de criação</label>
      </div>
      <Periods id="periods" @select="handlePeriod" />
    </b-col>
  </b-row>
</template>

<script>

export default {
  components: {
    Periods: () => import('@/components/General/Periods'),
    HealthPlanFilter: () => import('./HealthPlanFilter'),
  },
  data() {
    return {
      filters: {
        healthPlan: null,
        batchNumber: '',
        invoiceNumber: '',
        period: {
          type: 'create',
          start: '',
          end: ''
        }
      }
    }
  },
  methods: {
    handlePeriod(period) {
      this.filters.period.start = period.start
      this.filters.period.end = period.end
    },
    handleSelectedHealthPlans(newValue) {
      this.filters.healthPlan = newValue?.value ?? null
    }
  },
  watch: {
    filters: {
      handler(newValue) {
        this.$emit('change', newValue)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.filters-container {
  margin: 20px 0;

  label {
    font-weight: 700;
  }
}
</style>
